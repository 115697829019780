import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} key={item.header} key={item.url} className="column is-6">
        <section className="section">
          <div  className="has-text-centered">
            <div
              style={{
                width: "375px",
                display: "inline-block",
              }}
            >
              <a href={item.url}><PreviewCompatibleImage imageInfo={item} /></a>
            </div>
            <h3 style={{
              color: "#FF6633",
            }}><a href={item.url} style={{
              color: "#FF6633",
            }}>{item.header}</a></h3>
          </div>
          <p style={{
            color: "white",
          }}>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      header: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
