import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Closer from "../components/Closer.js"

// eslint-disable-next-line
export const PartnerPageTemplate = ({
  heading,
  description,
  intro,
}) => {

  return (
    <div className="content" style={{
      backgroundColor: "#000e2b",
    }}>
      <section className="section section--gradient" style={{
        backgroundImage: "url(/img/globe-bg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}>
        <div className="container">
            <div className="columns">
              <div className="column is-5 is-offset-1">
                <h2 className="has-text-weight-semibold is-size-2" style={{
                  color: "#30CAFF",
                }}>
                  {heading}
                </h2>
                <p>{description}</p>
              </div>
              <div className="column">
              </div>
            </div>

            <div className="columns">
              <div className="column is-10 is-offset-1">

                <div>
                <Features gridItems={intro.blurbs}/> 
                </div>

              </div>
            </div>
        </div>
      </section>
      <Closer />
    </div>
  );
};

PartnerPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  clients: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image5: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image6: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  partners: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image5: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image6: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const PartnerPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PartnerPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        clients={frontmatter.clients}
        partners={frontmatter.partners}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

PartnerPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PartnerPage;

export const partnerPageQuery = graphql`
  query PartnerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            header
            text
            url
          }
        }
      }
    }
  }
`;
